<template>
  <v-container fluid class='report-card align-self-sm-stretch justify-space-around'>
    <v-row class='d-flex justify-center card-title'>
      <v-row class='pa-0 ma-0 d-flex justify-space-between align-self-center'>
        <span class='report-title'> {{ $t('CUSTOMER_REPORT_TITLE') }} </span>
        <v-icon class='mr-4' small @click='fetchWidgetContent'>
          mdi-reload
        </v-icon>
      </v-row>
      <v-col cols='12'>
        <v-divider />
      </v-col>
    </v-row>
    <v-row class='d-flex justify-center align-stretch font-weight-black content-value primary--text display-3'>
      <span> {{ value }} </span>
    </v-row>
    <v-row class='report-footer'>
      <v-col>
        <v-icon @click='openFilters'>
          mdi-filter-outline
        </v-icon>
      </v-col>
      <v-col>
        <v-btn
          text
          color='primary'
          @click='fetchReportsContent'
        >
          Exportar
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model='filterDialog' fullscreen hide-overlay transition='dialog-bottom-transition'>
      <v-card>
        <Filters @onFilter='saveFilter' @applyFilters='fetchWidgetContent' @close='filterDialog = false' />
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import axios from 'axios';
  import * as XLSX from 'xlsx';
  import { format } from 'date-fns';

  export default {
    components: {
      Filters: () => import('./Filters.vue'),
    },
    data () {
      return {
        reportName: 'Customer Print',
        customerPrintDetails: 'Customer Print Details',
        value: null,
        printValue: null,
        filterText: null,
        filterDialog: false,
      };
    },
    mounted: function () {
      this.fetchWidgetContent();
    },
    methods: {
      async fetchWidgetContent () {
        this.filterDialog = false;
        const { data } = await axios({
          url: '/report-data',
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
          params: {
            name: this.reportName,
            filter: {
              desc_filter: this.filterText ? `${this.filterText}` : null,
            },
          },
        });
        this.value = data && data.data && data.data[0] ? data.data[0].total : null;
      },
      async fetchReportsContent () {
        const { data } = await axios({
          url: '/report-data',
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
          params: {
            name: this.customerPrintDetails,
            filter: {
              desc_filter: this.filterText ? `${this.filterText}` : null,
            },
          },
        });
        this.printValue = data && data.data && data.data[0] ? data.data : null;

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(
          this.printValue.map((values) => { // eslint-disable-line
            return {
              'Campo chave do documento': values.value,
              Nome: values.user_printed_name,
              CPF: values.cpf,
              Cargo: values.user_printed_role,
              Matricula: values.enrollment,
              Numero: values.user_printed_number,
              'Tipo de Documento': values.document_type,
              'Data de impressão': format(new Date(values.created_at), 'dd/MM/yyyy'),
              'Usuário responsável': values.name,
            };
          }) // eslint-disable-line
        );
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'relatorio_impressoes.xlsx');
      },
      removeWidget (id) {
        document.removeItem(id);
      },
      saveFilter (filter) {
        this.filterText = filter.text;
      },
      openFilters () {
        this.filterDialog = true;
      },
    },
  };
</script>
<style>
  .report-footer {
    position: absolute;
    bottom: 10vh;
  }

  html {
    overflow: hidden;
  }

  .report-card {
    background-color: #FFFFFF;
    height: 100vh;
    overflow-y: hidden;
  }

  .content-value {
    margin-top: 20vh !important;
  }

  .date-picker {
    z-index: 999;
    animation: createBox 0.5s;
  }

  @keyframes createBox {
    from {
      transform: scale(0);
    }

    to {
      transform: scale(1);
    }
  }

  .active-filters-text {
    font-size: 13px;
  }
</style>
